<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="审核状态" prop="state">
                <a-select placeholder="请选择审核状态" style="width: 100%" v-model="queryParam.state" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ResumeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">
                <a-form-item label="动态id 外键community_article.id" prop="articleId">
                  <a-input v-model="queryParam.articleId" placeholder="请输入动态id 外键community_article.id" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="退回记录id community_off_record.id" prop="offRecordId">
                  <a-input v-model="queryParam.offRecordId" placeholder="请输入退回记录id community_off_record.id" allow-clear/>
                </a-form-item>
              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['community:resume/apply:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['community:resume/apply:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['community:resume/apply:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['community:resume/apply:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <div slot="img" slot-scope="text, record">
          <img v-if="record.img" :src="record.img" style="width:60px;height:62px;cursor:pointer;" preview alt=""/>
        </div>
        <span slot="state" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ResumeEnum" :value="record.state"/>
        </span>
        <span slot="type" slot-scope="text, record">
          <custom-dict-tag :options="customDict.TrendsEnum" :value="record.type"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="articleCreateTime" slot-scope="text, record">
          {{ parseTime(record.articleCreateTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['community:resume/apply:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['community:resume/apply:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['community:resume/apply:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['community:resume/apply:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listResumeApply, delResumeApply } from '@/api/community/resumeApply'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'ResumeApply',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        state: null,
        content: null,
        img: null,
        articleId: null,
        offRecordId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '审核状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'state' },
          ellipsis: true,
          width: 100,
          align: 'center'
        },
        {
          title: '申诉内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申诉图片',
          dataIndex: 'img',
          scopedSlots: { customRender: 'img' },
          ellipsis: true,
          width: 140,
          align: 'center'
        },
        {
          title: '申诉人',
          dataIndex: 'declarant',
          ellipsis: true,
          width: 140,
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          width: 80,
          align: 'center'
        },
        {
          title: '关联名称',
          dataIndex: 'relationName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '下架内容',
          dataIndex: 'articleContent',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '动态id 外键community_article.id',
          dataIndex: 'articleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '退回记录id community_off_record.id',
          dataIndex: 'offRecordId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '发布时间',
          dataIndex: 'articleCreateTime',
          scopedSlots: { customRender: 'articleCreateTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '恢复申请时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 180,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {   ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询稿件恢复申请列表 */
    getList () {
      this.loading = true
      listResumeApply(this.queryParam).then(response => {
        this.list = response.data.records
        this.list.forEach(item => {
          const img = item.img.split(",")
          if (img && img.length > 1) {
            item.img = img[0]
          }
        })
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        state: undefined,
        content: undefined,
        img: undefined,
        articleId: undefined,
        offRecordId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delResumeApply(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('community/resume/apply/export', {
            ...that.queryParam
          }, `resume/apply_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
